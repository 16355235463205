import React from 'react';
import { FieldProps } from 'formik';
import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export interface TextFieldProps extends FieldProps {
    id: string;
    label?: string;
    type?: string;
    isDisabled?: boolean;
    className?: string;
    helpNote?: string;
    autoComplete?: string;
}

export const TextField = ({
    label,
    isDisabled,
    type,
    className,
    helpNote,
    autoComplete,
    field: { name, onChange },
    form: { touched, errors, values },
}: TextFieldProps): JSX.Element => (
    <div
        className={classNames('FormField', className, {
            'has-error': touched[name] && errors[name],
            'is-filled': !isEmpty(values[name]),
            'is-disabled': isDisabled,
        })}
    >
        <input
            id={name}
            autoComplete={autoComplete}
            type={type}
            name={name}
            disabled={!!isDisabled}
            onChange={onChange}
            value={get(values, name)}
            className="FormInput"
        />
        {!!label && (
            // eslint-disable-next-line jsx-a11y/label-has-for
            <label className="FormLabel" htmlFor={name}>
                {label}
            </label>
        )}
        {!!helpNote && (
            <span className={classNames('FormField-HelpNote', isDisabled && 'FormField-HelpNote-Disabled')}>
                {helpNote}
            </span>
        )}
    </div>
);

TextField.defaultProps = { type: 'text' };
