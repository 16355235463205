import React, { useState } from 'react';
import { FieldProps } from 'formik';
import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { SVGVisibility } from '../SVGIcons';
import { WithT } from 'i18next';

interface PasswordFieldProps extends FieldProps, WithT {
    id: string;
    label?: string;
    isDisabled?: boolean;
    helpNote?: string;
    autoComplete?: string;
}

export const PasswordField = ({
    t,
    label,
    isDisabled,
    autoComplete,
    helpNote,
    field: { name, onChange },
    form: { touched, errors, values },
}: PasswordFieldProps): JSX.Element => {
    const [passwordIsRevealed, setPasswordIsRevealed] = useState<boolean>(false);

    return (
        <div
            className={classNames('FormField', {
                'has-error': touched[name] && errors[name],
                'is-filled': !isEmpty(values[name]),
                'is-disabled': isDisabled,
            })}
        >
            <input
                id={name}
                autoComplete={autoComplete}
                type={passwordIsRevealed ? 'text' : 'password'}
                onChange={onChange}
                value={values[name]}
                className="FormInput"
                disabled={!!isDisabled}
            />
            {!!label && (
                // eslint-disable-next-line jsx-a11y/label-has-for
                <label className="FormLabel" htmlFor={name}>
                    {label}
                </label>
            )}
            {!!helpNote && <span className="FormField-HelpNote">{helpNote}</span>}
            <button
                type="button"
                className="FormField-RevealButton has-tiny-tooltip"
                aria-hidden="true"
                onMouseDown={(): void => setPasswordIsRevealed(true)}
                onMouseUp={(): void => setPasswordIsRevealed(false)}
                onClick={(event: React.SyntheticEvent): void => event.preventDefault()}
            >
                <SVGVisibility />
                <span className="tiny-tooltip">{t('common:revealPassword')}</span>
            </button>
        </div>
    );
};
