import React, { useEffect, useState, FocusEvent, useRef, RefObject } from 'react';
import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { TextFieldProps } from './TextField';
import dayjs, { Dayjs } from 'dayjs';
import { SVGCalendar } from '../SVGIcons/SVGCalendar';
import Calendar from '../DatePicker/Calendar';
import { useToggleState } from '../../../hooks';

type DateFieldProps = Omit<TextFieldProps, 'type' | 'autoComplete'>;

const supportsDateInput = (): boolean => {
    const input = document.createElement('input');
    input.setAttribute('type', 'date');

    const notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);

    return input.value !== notADateValue;
};

const renderDatePicker = (
    date: Dayjs,
    displayCalendar: boolean,
    toggleCalendar: () => void,
    setValue: (m: string) => void,
    calendarRef: RefObject<HTMLDivElement>
): JSX.Element => (
    <div ref={calendarRef} tabIndex={-1}>
        <button className="DateField-DatePicker" type="button" aria-expanded={displayCalendar} onClick={toggleCalendar}>
            <SVGCalendar aria-hidden={true} focusable={false} />
        </button>
        <Calendar
            selectedDate={date}
            onDaySelected={(date: Dayjs): void => {
                setValue(date.format('YYYY-MM-DD'));
                toggleCalendar();
            }}
            className={classNames('DateField-Calendar', { displayed: displayCalendar })}
            firstActiveDay={dayjs().subtract(2, 'years')}
            lastActiveDay={dayjs().add(2, 'years')}
            componentName="date-field"
        />
    </div>
);

export const DateField = ({
    label,
    isDisabled,
    className,
    helpNote,
    field: { name, onChange },
    form: { touched, errors, values, setFieldValue },
}: DateFieldProps): JSX.Element => {
    const [displayCalendar, toggleCalendar] = useToggleState(false);
    const [value, setValue] = useState(get(values, name));
    const hasDateInputSupport = supportsDateInput();
    const calendarRef = useRef<HTMLDivElement>(null);

    useEffect((): void => {
        if (isFunction(setFieldValue)) {
            setFieldValue(name, value);
        }
    }, [name, setFieldValue, value]);

    const formattedValue = dayjs(get(values, name) || Date.now()).format('YYYY-MM-DD');

    return (
        <div
            className={classNames('FormField', 'DateField', className, {
                'has-error': get(touched, name) && get(errors, name),
                'is-filled': !isEmpty(get(values, name)),
                'is-disabled': isDisabled,
            })}
        >
            <input
                id={name}
                type="date"
                name={name}
                disabled={!!isDisabled}
                onChange={onChange}
                value={formattedValue}
                readOnly={!hasDateInputSupport}
                onBlur={(event: FocusEvent<HTMLInputElement>): void => {
                    const relatedTarget = event.relatedTarget || document.activeElement;
                    if (
                        displayCalendar &&
                        !(
                            calendarRef.current !== null &&
                            relatedTarget !== null &&
                            calendarRef.current.contains(relatedTarget as Node)
                        )
                    ) {
                        toggleCalendar();
                    }
                }}
                onClick={(): void => {
                    if (!hasDateInputSupport) {
                        toggleCalendar();
                    }
                }}
                className="FormInput"
            />
            {!!label && (
                // eslint-disable-next-line jsx-a11y/label-has-for
                <label className="FormLabel" htmlFor={name}>
                    {label}
                </label>
            )}
            {!!helpNote && <span className="FormField-HelpNote">{helpNote}</span>}
            {!hasDateInputSupport &&
                renderDatePicker(
                    dayjs(get(values, name) || Date.now()),
                    displayCalendar,
                    toggleCalendar,
                    setValue,
                    calendarRef
                )}
        </div>
    );
};
