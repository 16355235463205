import React, { ChangeEvent, Component } from 'react';
import { FieldProps } from 'formik';
import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';
import identity from 'lodash/identity';
import uniqueId from 'lodash/uniqueId';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import AriaRole from '../../AriaRole';
import { notifyPolite } from '../A11y/Notification';
import { WithTranslation } from 'next-i18next';

export interface TextAreaFieldProps extends FieldProps, WithTranslation {
    label: string;

    /**
     * The initial text
     */
    text?: string;

    className?: string;

    /**
     * Value displayed as placeholder in case that the textarea is empty.
     */
    placeholder: string;

    /**
     * Max text length for the textarea
     */
    maxTextLength?: number;

    /**
     * If the field is disabled or not
     */
    isDisabled?: boolean;

    /**
     * Display the component in read only state.
     */
    readOnly?: boolean;

    /**
     * Called when text changes
     */
    onChange?: (text: string) => void;

    /**
     * Whether errors should be displayed below the field
     */
    displayErrors?: boolean;
}

interface TextAreaFieldState {
    text: string;
}

export class TextAreaField extends Component<TextAreaFieldProps, TextAreaFieldState> {
    private readonly textAreaId: string;
    private readonly labelId: string;

    public static defaultProps: Partial<TextAreaFieldProps> = {
        text: '',
        onChange: noop,
        t: identity,
        displayErrors: true,
    };

    private giveLengthNotification(): void {
        const charCountDifferenceFromLimit: number = this.computeCharDifferenceFromLimit();
        const lengthNotification: string | null = this.computeLengthNotification(charCountDifferenceFromLimit);
        if (lengthNotification) {
            notifyPolite(lengthNotification);
        }
    }

    public constructor(props: TextAreaFieldProps) {
        super(props);
        this.textAreaId = uniqueId('textarea-');
        this.labelId = `${this.textAreaId}-label`;
        this.handleTextUpdate = this.handleTextUpdate.bind(this);
        this.giveLengthNotification = debounce(this.giveLengthNotification, 500).bind(this);

        this.state = {
            text: this.props.text!,
        };
    }

    private computeCharDifferenceFromLimit(): number {
        const textLength: number = this.state.text != null ? this.state.text.length : 0;
        return this.props.maxTextLength! - textLength;
    }

    private handleTextUpdate(evt: ChangeEvent<HTMLTextAreaElement>): void {
        const text: string = evt.target.value;
        this.props.onChange!(text);
        this.setState({ text }, this.giveLengthNotification);
        this.props.form.setFieldValue(this.props.field.name, text);
    }

    private computeLengthNotification(charCountDifferenceFromLimit: number): string | null {
        if (charCountDifferenceFromLimit <= 0) {
            return this.props.t('textAreaMessageLimitReached');
        } else if (charCountDifferenceFromLimit <= 50) {
            return this.props.t('textAreaMessageLimitApproached', { charCountDifferenceFromLimit });
        } else {
            return null;
        }
    }

    public render(): JSX.Element {
        const inputClassName = 'FormInput';
        let inputElement: JSX.Element;
        let errorMessageDiv: JSX.Element | null = null;

        const hasError =
            this.props.form.errors[this.props.field.name] && this.props.form.touched[this.props.field.name];

        if (hasError) {
            errorMessageDiv = <div className="FormErrors">{this.props.form.errors[this.props.field.name]}</div>;
        }

        if (this.props.readOnly) {
            inputElement = (
                <span className="FormInput" aria-readonly={true} role={AriaRole.TEXTBOX} aria-multiline={true}>
                    {this.props.form.values[this.props.field.name]}
                </span>
            );
        } else {
            inputElement = (
                <textarea
                    className={inputClassName}
                    name={this.props.field.name}
                    onChange={this.handleTextUpdate}
                    value={this.props.form.values[this.props.field.name]}
                    maxLength={this.props.maxTextLength}
                    disabled={this.props.isDisabled}
                    id={this.textAreaId}
                    aria-labelledby={this.labelId}
                    aria-multiline={true}
                />
            );
        }

        const cssClassNames: string = classNames('TextAreaField FormField', this.props.className, {
            'has-error':
                this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name],
            'is-filled': !isEmpty(this.state.text) || !isEmpty(this.props.form.values[this.props.field.name]),
            'is-disabled': this.props.isDisabled,
        });

        const charCountText = `${this.state.text.length} / ${this.props.maxTextLength} caractères max.`;

        return (
            <div className={cssClassNames}>
                {inputElement}
                <label id={this.labelId} className="FormLabel" htmlFor={this.textAreaId}>
                    {this.props.label}
                </label>
                {this.props.maxTextLength && <span className="TextAreaField-CharCount">{charCountText}</span>}
                {this.props.displayErrors && errorMessageDiv}
            </div>
        );
    }
}
