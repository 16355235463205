import React, { SVGAttributes } from 'react';

export function SVGCalendar(props: SVGAttributes<SVGElement>): JSX.Element {
    return (
        <svg width="16" height="19" {...props}>
            <path
                d="M14.062 2.5c.47 0 .868.164 1.196.492.328.328.492.727.492 1.196v12.374c0 .47-.164.868-.492 1.196a1.627 1.627 0 0 1-1.196.492H1.687c-.468 0-.867-.164-1.195-.492A1.627 1.627 0 0 1 0 16.562V4.188c0-.47.164-.868.492-1.196A1.627 1.627 0 0 1 1.687 2.5h1.688V.531c0-.07.03-.134.088-.193A.269.269 0 0 1 3.656.25h.563c.07 0 .135.03.193.088A.269.269 0 0 1 4.5.53V2.5h6.75V.531c0-.07.03-.134.088-.193A.269.269 0 0 1 11.53.25h.563c.07 0 .134.03.193.088a.269.269 0 0 1 .088.193V2.5h1.687zM4.887 17.125v-2.637H1.125v2.074c0 .165.053.3.158.405.106.105.24.158.404.158h3.2zm0-3.762v-2.601H1.125v2.601h3.762zM1.125 9.637h3.762V7H1.125v2.637zm8.613 7.488v-2.637H6.012v2.637h3.726zm0-3.762v-2.601H6.012v2.601h3.726zM6.012 9.637h3.726V7H6.012v2.637zm8.613 6.925v-2.074h-3.762v2.637h3.2a.547.547 0 0 0 .404-.158.547.547 0 0 0 .158-.405zm0-3.199v-2.601h-3.762v2.601h3.762zm0-3.726V7h-3.762v2.637h3.762zm0-3.762V4.188a.547.547 0 0 0-.158-.405.547.547 0 0 0-.405-.158H1.687a.547.547 0 0 0-.404.158.547.547 0 0 0-.158.405v1.687h13.5z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}
