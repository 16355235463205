import React, { Component } from 'react';
import { Dayjs } from 'dayjs';
import AriaRole from '../../AriaRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import classNames from 'clsx';

library.add(faArrowCircleRight);

/**
 * Flyout to display and modify current month
 * On single click on arrows the calendar changes by 1 month
 * On long press (anything longer than 1 second), it changes by 1 month every 0.1 seconds
 */
export interface MonthNavigationProps {
    // defines which date is used to generate the list of days for the month view
    // (not necessarily today !!)
    currentDate: Dayjs;

    /**
     * Called when the selected month changes.
     */
    onMonthSelected: (date: Dayjs) => void;

    // @see Calendar `firstActiveDay` prop
    firstActiveDay?: Dayjs;

    // @see Calendar `lastActiveDay` prop
    lastActiveDay?: Dayjs;
}

export class MonthNavigation extends Component<MonthNavigationProps> {
    private monthTimer: number | undefined;
    private delayTimeout: number | undefined;
    private static switchToFastForwardTimeout: number = 1000;
    private static checkIfStillPressedTimeout: number = 100;

    public constructor(props: MonthNavigationProps) {
        super(props);
        this.onNextMonthClick = this.onNextMonthClick.bind(this);
        this.clearTimeouts = this.clearTimeouts.bind(this);
        this.onPrevMonthClick = this.onPrevMonthClick.bind(this);
        this.isNextButtonEnabled = this.isNextButtonEnabled.bind(this);
        this.isPrevButtonEnabled = this.isPrevButtonEnabled.bind(this);
        this.fastPrev = this.fastPrev.bind(this);
        this.fastNext = this.fastNext.bind(this);
    }

    private fastNext(): void {
        if (this.isNextButtonEnabled()) {
            clearTimeout(this.monthTimer);
            // at each call wen add one month
            this.props.onMonthSelected(this.props.currentDate.add(1, 'month'));
            // we set an interval at which this function will call itself
            this.delayTimeout = window.setTimeout(this.fastNext, MonthNavigation.checkIfStillPressedTimeout);
        }
    }

    private fastPrev(): void {
        if (this.isPrevButtonEnabled()) {
            clearTimeout(this.monthTimer);
            this.props.onMonthSelected(this.props.currentDate.subtract(1, 'month'));
            this.delayTimeout = window.setTimeout(this.fastPrev, MonthNavigation.checkIfStillPressedTimeout);
        }
    }

    private onNextMonthClick(e: React.MouseEvent | React.TouchEvent): void {
        e.preventDefault();
        this.props.onMonthSelected(this.props.currentDate.add(1, 'month'));
        // go faster through the months if it is long-pressed...
        this.monthTimer = window.setTimeout(this.fastNext, MonthNavigation.switchToFastForwardTimeout);
    }

    private onPrevMonthClick(e: React.MouseEvent | React.TouchEvent): void {
        e.preventDefault();
        this.props.onMonthSelected(this.props.currentDate.subtract(1, 'month'));
        this.monthTimer = window.setTimeout(this.fastPrev, MonthNavigation.switchToFastForwardTimeout);
    }

    private clearTimeouts(): void {
        clearTimeout(this.delayTimeout);
        clearTimeout(this.monthTimer);
    }

    private isNextButtonEnabled(): boolean {
        return !!this.props.lastActiveDay && this.props.lastActiveDay.diff(this.props.currentDate, 'months') > 0;
    }

    private isPrevButtonEnabled(): boolean {
        return !!this.props.firstActiveDay && this.props.currentDate.diff(this.props.firstActiveDay, 'days') > 0;
    }

    private renderNextMonthButton(): JSX.Element {
        const isNextButtonEnabled: boolean = this.isNextButtonEnabled();
        const className: string = classNames('Calendar-NextMonth', {
            'Calendar-NextMonth-disabled': !isNextButtonEnabled,
        });
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                className={className}
                onMouseDown={isNextButtonEnabled ? this.onNextMonthClick : undefined}
                onMouseUp={isNextButtonEnabled ? this.clearTimeouts : undefined}
                onMouseOut={isNextButtonEnabled ? this.clearTimeouts : undefined}
                onBlur={isNextButtonEnabled ? this.clearTimeouts : undefined}
                onTouchStart={isNextButtonEnabled ? this.onNextMonthClick : undefined}
                onTouchEnd={isNextButtonEnabled ? this.clearTimeouts : undefined}
                role={AriaRole.BUTTON}
            >
                <FontAwesomeIcon size="2x" aria-hidden="true" icon={faArrowCircleRight} />
            </div>
        );
    }

    private renderPrevMonthButton(): JSX.Element {
        const isPrevButtonEnabled: boolean = this.isPrevButtonEnabled();
        const className: string = classNames('Calendar-PrevMonth', {
            'Calendar-PrevMonth-disabled': !isPrevButtonEnabled,
        });
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                className={className}
                onMouseDown={isPrevButtonEnabled ? this.onPrevMonthClick : undefined}
                onMouseUp={isPrevButtonEnabled ? this.clearTimeouts : undefined}
                onMouseOut={isPrevButtonEnabled ? this.clearTimeouts : undefined}
                onBlur={isPrevButtonEnabled ? this.clearTimeouts : undefined}
                onTouchStart={isPrevButtonEnabled ? this.onPrevMonthClick : undefined}
                onTouchEnd={isPrevButtonEnabled ? this.clearTimeouts : undefined}
                role={AriaRole.BUTTON}
            >
                <FontAwesomeIcon size="2x" aria-hidden="true" icon={faArrowCircleLeft} />
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <div className="Calendar-MonthNavigation">
                {this.renderNextMonthButton()}
                {this.renderPrevMonthButton()}
                <div className="Calendar-CurrentMonth">{this.props.currentDate.format('MMMM YYYY')}</div>
            </div>
        );
    }
}
