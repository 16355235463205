import React, { useState, useEffect } from 'react';
import { MonthNavigation } from './MonthNavigation';
import { MonthView } from './MonthView';
import dayjs, { Dayjs } from 'dayjs';
import classNames from 'clsx';

export interface CalendarProps {
    /**
     * The date initially selected. The Calendar will
     * show its corresponding month view.
     */
    selectedDate?: Dayjs;
    /**
     * Called when a day is selected.
     */
    onDaySelected: (newDate: Dayjs) => void;
    componentName: string;
    /**
     * The earliest date that can be selected in the Calendar
     */
    firstActiveDay?: Dayjs;
    /**
     * The most recent past date that can be selected in the Calendar.
     * Or the latest future date that can be selected.
     * Anyway, you got it.
     */
    lastActiveDay?: Dayjs;
    /**
     * Determines whether the first day in a week is 'monday' or 'sunday'
     * defaults to 'monday'
     */
    firstDayInWeek?: 'monday' | 'sunday';
    className?: string;
}

const Calendar: React.FC<CalendarProps> = ({
    selectedDate,
    onDaySelected,
    componentName,
    firstActiveDay,
    lastActiveDay,
    firstDayInWeek = 'monday',
    className,
}): JSX.Element => {
    const [currentDate, setCurrentDate] = useState(
        selectedDate ? dayjs(selectedDate).startOf('month') : dayjs().startOf('month')
    );

    useEffect((): void => {
        setCurrentDate(dayjs(selectedDate));
    }, [selectedDate]);

    const setSelectedDate = (date: Dayjs): void => {
        if (onDaySelected) {
            onDaySelected(date);
        }
    };

    const monthViewComponentName: string = componentName + '-MonthView';

    return (
        <div className={classNames('Calendar', className)} aria-hidden={true}>
            <MonthNavigation
                currentDate={currentDate}
                onMonthSelected={setCurrentDate}
                firstActiveDay={firstActiveDay}
                lastActiveDay={lastActiveDay}
            />
            <MonthView
                currentDate={currentDate}
                onDaySelected={setSelectedDate}
                selectedDate={selectedDate}
                componentName={monthViewComponentName}
                firstActiveDay={firstActiveDay}
                lastActiveDay={lastActiveDay}
                firstDayInWeek={firstDayInWeek}
            />
        </div>
    );
};

Calendar.defaultProps = {
    firstDayInWeek: 'monday',
};

export default Calendar;
