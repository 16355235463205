import React, { Component, KeyboardEventHandler } from 'react';
import classNames from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import includes from 'lodash/includes';
import times from 'lodash/times';
import AriaRole from '../../AriaRole';
import EventKeys from '../../EventKeys';

export interface MonthViewProps {
    // defines which date is used to generate the list of days for the month view
    // (not necessarily today !!)
    currentDate: Dayjs;

    // defines which date is currently selected
    selectedDate?: Dayjs;

    // called when a day is selected
    onDaySelected: (date: Dayjs) => void;

    // name of component
    componentName: string;

    // first active day for calendar
    firstActiveDay?: Dayjs;

    // last active day for calendar
    lastActiveDay?: Dayjs;

    /**
     * Determines whether the first day in a week is Monday or Sunday
     * defaults to 'monday'
     */
    firstDayInWeek?: 'monday' | 'sunday';
}

export class MonthView extends Component<MonthViewProps> {
    public static defaultProps: Partial<MonthViewProps> = {
        firstDayInWeek: 'monday',
    };

    public constructor(props: MonthViewProps) {
        super(props);
    }

    /**
     * Returns a list of dayjs objects to be used in the month view.
     *
     * The list will include the last week of the previous month if the last
     * day of the previous month and the first day of the displayed month are
     * not in the same week.
     */
    public getMonthDays(fromDayjs: Dayjs): Dayjs[] {
        const firstOfMonth: Dayjs = fromDayjs.startOf('month');
        const lastInPreviousMonth: Dayjs = dayjs(firstOfMonth).subtract(1, 'days');
        const currentDay: Dayjs = dayjs(firstOfMonth).startOf('week');

        // include last week of previous month if needed
        if (lastInPreviousMonth.isBefore(currentDay)) {
            currentDay.subtract(1, 'weeks');
        }

        if (this.props.firstDayInWeek === 'sunday') {
            currentDay.subtract(1, 'days');
        }

        // month view always contains six weeks
        return times(
            6 * 7,
            (): Dayjs => {
                const day = dayjs(currentDay);
                currentDay.add(1, 'days');
                return day;
            }
        );
    }

    public renderDays(days: Dayjs[]): JSX.Element[] {
        return days.map((day: Dayjs): JSX.Element => this.renderDay(day));
    }

    private handleDayClick(day: Dayjs): void {
        if (!this.isDisabled(day)) {
            this.props.onDaySelected(day);
        }
    }

    private handleKeyDown(day: Dayjs, e: KeyboardEvent): void {
        if (includes([EventKeys.SPACE, EventKeys.ENTER], e.key) && !this.isDisabled(day)) {
            this.props.onDaySelected(day);
        }
    }

    private isDisabled(day: Dayjs): boolean {
        return (
            !!this.props.firstActiveDay &&
            !!this.props.lastActiveDay &&
            (day < this.props.firstActiveDay || this.props.lastActiveDay < day)
        );
    }

    /**
     * Returns a react element for one day
     */
    public renderDay(day: Dayjs): JSX.Element {
        const isDisabled: boolean = this.isDisabled(day);
        const isSame: boolean = this.props.currentDate.isSame(day, 'month');
        const className: string = classNames('Calendar-MonthViewDay', {
            'Calendar-MonthViewDay-same': isSame,
            'Calendar-MonthViewDay-other': !isSame,
            'Calendar-MonthViewDay-current': dayjs().isSame(day, 'day'),
            'Calendar-MonthViewDay-selected': this.props.selectedDate && this.props.selectedDate.isSame(day, 'day'),
            'Calendar-MonthViewDay-disabled': isDisabled,
        });
        const key: string = this.props.componentName + '-MonthViewDay-Month-' + day.month() + '-Day-' + day.date();

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                role={AriaRole.BUTTON}
                className={className}
                onKeyDown={(this.handleKeyDown.bind(this, day) as unknown) as KeyboardEventHandler<HTMLDivElement>}
                onClick={this.handleDayClick.bind(this, day)}
                key={key}
            >
                <span className="Calendar-MonthViewDay-Number">{day.format('D')}</span>
            </div>
        );
    }

    /**
     * Returns a react element for all weekday headers
     */
    public renderWeekdayHeaders(days: Dayjs[]): JSX.Element[] {
        return days.map(
            (day: Dayjs): JSX.Element => {
                const key: string =
                    this.props.componentName +
                    '-MonthViewWeekdayHeader-Day-' +
                    // Iso Week day
                    (dayjs().day() === 0 ? 7 : dayjs().day());
                return (
                    <div className="Calendar-MonthViewWeekdayHeader" key={key}>
                        {day.format('dd')}
                    </div>
                );
            }
        );
    }

    public render(): JSX.Element {
        const days: Dayjs[] = this.getMonthDays(this.props.currentDate);
        return (
            <div className="Calendar-MonthView">
                <div className="Calendar-MonthViewWeekdaysContainer">{this.renderWeekdayHeaders(days.slice(0, 7))}</div>
                {this.renderDays(days)}
            </div>
        );
    }
}
