import React, { FunctionComponent } from 'react';
import { Color } from 'chroma-js';
import classNames from 'clsx';

export interface HorizontalRuleProps {
    className?: string;

    /**
     * The color of the horizontal rule.
     * If passed, it overrides any backgroundColor / color set as a CSS
     * property via className prop, since it is applied as an inline style.
     * Defaults to #eeeeee
     */
    color?: Color;

    /**
     * The height (thickness) of the horizontal rule.
     * If passed, it overrides any height CSS
     * property via className prop, since it is applied as an inline style.
     * Defaults to 1px
     */
    height?: number;
}

export const HorizontalRule: FunctionComponent<HorizontalRuleProps> = ({
    className,
    height,
    color,
}: HorizontalRuleProps): JSX.Element => {
    return (
        <hr
            className={classNames('FormField', 'HorizontalRule', className)}
            style={{
                backgroundColor: color ? color.hex() : undefined,
                height: height != null && isFinite(height) && height > 0 ? height : undefined,
            }}
        />
    );
};
